



















































import {Component, Vue} from 'vue-property-decorator';
import SettingsItem from '@/components/SettingsItem.vue';
import DaySettings from '@/components/DaySettings.vue';
import Selector from '@/components/Selector.vue';
import {settingsStore} from '@/store/modules/settings';
import {computeTimeWithOffset, timeZoneAndTime} from '@/utils/helpers';

@Component({
  name: 'localisation',
  components: {Selector, DaySettings, SettingsItem},
  filters: {timeZoneAndTime, computeTimeWithOffset}
})
export default class Localisation extends Vue {

  dirty: boolean = false;

  get date() {
    return new Date()
  }

  get timeZone() {
    return settingsStore.timeZone
  }

  set timeZone(index: number) {
    settingsStore.changeTimeZone(index);
  }

  get timeZones() {
    return settingsStore.timeZones
  }
}
